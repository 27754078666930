<template>
  <div>
    <!-- <div
      style="width:120px;text-align:center; padding:10px;font-size:16px;background:#a3cdff;color:white"
    >
      设备地图查询
    </div> -->
    <section
      style="display:flex;margin:10px 0 10px 0px; justify-content:flex-start"
    >
      <!-- <div class="box">
        <div class="title">输入电子围栏名称（例如：新安江街道）</div>
        <el-amap-search-box
          class="search-box"
          :search-option="searchOption"
          :on-search-result="onSearchResult"
        ></el-amap-search-box>
      </div> -->
      <div class="box" >
        <div class="title">输入电子围栏名称（例如：新安江街道）</div>
        <div class="searchBox">
          <!-- <el-input v-model="deviceCode"></el-input> -->
           <el-select v-model="electronicFenceId" filterable clearable placeholder="请选择" @change="selectElectronicFence" style="width:100%;">
              <el-option
                v-for="item in electronicFenceList"
                :key="item.id"
                :label="item.electronicFenceName"
                :value="item.id">
              </el-option>
            </el-select>
          <!-- <div class="searchBtn" @click="onSearchCode">搜索</div> -->
        </div>
      </div>
      <div class="box" style="margin-left:20px">
        <div class="title">输入设备编码（例如：0103184516BE461E）</div>
        <div class="searchBox">
          <el-input v-model="deviceCode"></el-input>
          <div class="searchBtn" @click="onSearchCode">搜索</div>
        </div>
      </div>
    </section>

    <div id="container" style="width: 100%; height: 700px;box-shadow: 0 2px 10px 0 rgba(14,33,39,.2);">
      <el-amap
        ref="map"
        vid="amapDemo"
        :amap-manager="amapManager"
        :center="center"
        :zoom="zoom"
        :plugin="plugin"
        :events="events"
        class="amap-demo"
      >
      </el-amap>
      <p>{{ address }}</p>
      <p>{{ center }}</p>
    </div>
  </div>
</template>

<script>
import { AMapManager } from "vue-amap";
let amapManager = new AMapManager();
export default {
  data() {
    const self = this;
    return {
      searchMarker:null,
      equipmentList:[],
      electronicFenceList:[],
      electronicFenceId:'',
      tableData: [],
      arr: [],
      arr1: [],
      lng: "",
      lat: "",
      markersArray: [],
      clearOverlays: [],
      fristForm: {
        address: "",
        longitude: "",
        latitude: ""
      },
      queryInfo: {
        // 当前页数
        currPage: 1,
        // 每页显示多少数据
        pageSize: 10,
        //查询条件
        condition: {
          id: "",
          keyWord: ""
        }
      },
      deviceCode: "",
      //要标记的所有点的经纬度
      lnglats: [
        // [118.09451569531248, 39.41120721908811],
        // [117.75668610546873, 39.38361530584159],
        // [116.8928860566406, 31.82895056195348],
      ],
      searchOption: {
        city: "全国", //搜索范围
        citylimit: true //限制搜索范围
        //city: "", citylimit: false, // 不限制搜索范围搜索，比如想全国搜索
      },
      map: null,
      lng: "",
      lat: "",
      address: "",
      marker: "",
      amapManager,
      zoom: 12,
      center: [121.59996, 31.197646],
      events: {
        init: o => {
          o.getCity(result => {
            // console.log(result);
            // this.searchOption.city=result.city
          });
        },
        moveend: () => {},
        zoomchange: () => {},
        click: e => {
          console.log(e.lnglat);
          self.lng = e.lnglat.lng;
          self.lat = e.lnglat.lat;
          self.center = [self.lng, self.lat];
          //   console.log(e.lnglat, 1999);

          let o = amapManager.getMap();
          if (!self.marker) {
            self.marker = new AMap.Marker({
              position: e.lnglat
            });
            self.marker.setMap(o);
          }
          self.marker.setPosition(e.lnglat);
          let geocoder = new AMap.Geocoder({});

          geocoder.getAddress(e.lnglat, function(status, result) {
            if (status === "complete" && result.info === "OK") {
              self.input = result.regeocode.formattedAddress;
              document.querySelector(".search-box-wrapper input").value =
                self.input;
            } else {
              console.log.error("根据经纬度查询地址失败");
            }
          });
        }
      },
      plugin: [
        "ToolBar",
        {
          pName: "MapType",
          defaultType: 0,
          events: {
            init(o) {
              //   console.log(o);
            }
          }
        }
      ],
      plugin: [
        {
          pName: "Geolocation",
          events: {
            init(o) {
              // o 是高德地图定位插件实例
              o.getCurrentPosition((status, result) => {
                // console.log(JSON.stringify(result));
                if (result && result.position) {
                  self.lng = result.position.lng;
                  self.lat = result.position.lat;
                  self.address = result.formattedAddress;
                  self.center = [self.lng, self.lat];
                  //   console.log(self.center, 99666);
                  let o = amapManager.getMap();
                  if (!self.marker) {
                    self.marker = new AMap.Marker({
                      position: self.center
                    });
                    self.marker.setMap(o);
                  }
                  self.marker.setPosition(self.center);
                }
              });
            }
          }
        }
      ]
    };
  },
  created() {
    this.getListData();
    
  },
  mounted() {
    this.carGPSIP();
  },
  methods: {
    selectElectronicFence(){
            console.log('p1111');
      var that = this
      let data = {}
      data.id = that.electronicFenceId
      that.$http.post('/electronicFence/queryOne',data).then(res => {
        if(res.data.code == 200) {
            let item = res.data.data
            let arr = item.mapPoint.split(";");
            let arr1 = []
             for (let i = 0; i < arr.length; i++) {
                arr1.push(arr[i].split(","));
              }
            var polygon = new AMap.Polygon({
              fillColor: "#f66600",
              fillOpacity: 0.4,
              path: arr1,
              strokeColor: "#FF33FF",
              strokeOpacity: 0.2,
              zIndex: 10
            });
            if (item.fenceBuildingStatus == 0) {
              polygon.fillColor = "#1791fc";
            } else if (item.fenceBuildingStatus == 1) {
              polygon.fillColor = "#8ec14a";
            } else {
              polygon.fillColor = "#f66600";
            }

                  console.log('p2222');
             //多边形覆盖物上点击显示窗体;
              polygon.on('click', function (tar) {
                      console.log('p3333');
                  that.openInfo(tar,item)
              });
              console.log('polygon',polygon);
            that.myMap.add(polygon);
            that.myMap.setZoomAndCenter(16,[item.longitude,item.latitude]) 
        }
      })
    },
    //查询电子围栏
    getElectronicFenceList(){
      var that = this
       const data = {}
       this.$http.post('/electronicFence/queryList',data).then(res => {
         if(res.data.code == 200) {
             that.electronicFenceList = res.data.data
             res.data.data.forEach(e => {
             let arr = e.mapPoint.split(";");
             let arr1 = []
             for (let i = 0; i < arr.length; i++) {
                arr1.push(arr[i].split(","));
              }
            var polygon = new AMap.Polygon({
              fillColor: "#1791fc",
              fillOpacity: 0.4,
              path: arr1,
              strokeColor: "#FF33FF",
              strokeOpacity: 0.2,
              zIndex: 10,
            });
            if (e.fenceBuildingStatus == 0) {
              polygon.fillColor = "#1791fc";
            } else if (e.fenceBuildingStatus == 1) {
              polygon.fillColor = "#8ec14a";
            } else {
              polygon.fillColor = "#f66600";
            }

            //多边形覆盖物上点击显示窗体;
              polygon.on('click', function (tar) {

                  that.openInfo(tar,e)
              });
             that.myMap.add(polygon);
             })
          }
       })
    },
    //在指定位置打开信息窗体
     openInfo(tar,e) {
      console.log('tar',tar);
      console.log('e',e);
        //构建信息窗体中显示的内容
        var info = [];
        info.push("<div style=\"padding:7px 0px 0px 0px;width:200px;\"><h4>" + e.electronicFenceName +"</h4>");
        info.push("<p class='input-item'>电话 : " + e.adminPhone +"  负责人 : " + e.administratorName + "</p>");
        info.push("<p class='input-item'>站点简介 :" + e.description + "</p></div></div>");

        let infoWindow = new AMap.InfoWindow({
            content: info.join(""),  //使用默认信息窗体框样式，显示信息内容
            // autoMove:true,
            anchor:'bottom-center',
            // position:[e.lnglat.lng,e.lnglat.lat],
        });
        infoWindow.open(this.myMap, tar.lnglat);
    },
    gotoDetail() {
      this.$router.push({
        path: "/equipment"
      });
    },
    //搜索地名
    onSearchResult(pois) {
      if (pois.length > 0) {
        let { lng, lat, name, location } = pois[0];
        let center = [lng, lat];
        console.log("中心点", center);
        console.log(pois[0].name);
        this.$http
          .post("/device/list", {
            page: 1,
            limit: 10,
            condition: {
              electronicFenceName: pois[0].name
            }
          })
          .then(response => {
            console.log("---------");
            if (response.data.code == 200) {
              // debugger;
              let arr = [];
              if (response.data.data.data.length > 0) {
                response.data.data.data.forEach(v => {
                  arr.push([parseFloat(v.longitude), parseFloat(v.latitude)]);
                });
              }
              this.lnglats = [...arr];
              // console.log(this.lnglats);
              this.carGPSIP();
            }
          });
        this.lng = lng;
        this.lat = lat;
        this.center = [lng, lat];
        let o = amapManager.getMap();
        if (!this.marker) {
          this.marker = new AMap.Marker({
            position: center
          });
          this.marker.setMap(o);
        }
        this.marker.setPosition(center);
        this.myMap.setCenter(center);
        // 近来补充  根据经纬度查询地址
        let geocoder = new AMap.Geocoder({});
        let that = this;
        geocoder.getAddress(location, function(status, result) {
          console.log(status, result);
          if (status === "complete" && result.regeocode) {
            that.address = result.regeocode.formattedAddress;
          } else {
            console.log("根据经纬度查询地址失败");
          }
        });
      }
    },
    //搜索编码
    onSearchCode() {
      var that = this
      // if (that.searchMarker!=null) {
         that.myMap.remove(that.searchMarker)
      // }
      if (that.deviceCode) {
        that.$http
          .post("/device/list", {
            page: 1,
            limit: 10,
            condition: {
              deviceCode: that.deviceCode
            }
          })
          .then(response => {
            console.log("---------");
            if (response.data.code == 200) {
              // debugger;
              let arr = response.data.data.data
              if (arr.length > 0) {
                // response.data.data.data.forEach(v => {
                //   arr.push([parseFloat(v.longitude), parseFloat(v.latitude)]);
                // });
                let i = 0 
                arr.forEach(e => {
                  that.tableData.forEach( (ite,idx) => {
                    if (ite.id == e.id ) {
                       i = idx
                    }
                  })
                })
                that.myMap.setZoomAndCenter(16,[arr[0].longitude,arr[0].latitude]) 
                 this.searchMarker = new AMap.Marker({
                  position: [arr[0].longitude,arr[0].latitude], //不同标记点的经纬度
                  map: that.myMap,
                  animation:'AMAP_ANIMATION_DROP',
                  icon:'https://webapi.amap.com/theme/v1.3/markers/n/mark_r.png'
                });
                 this.searchMarker.on("click", function markerClick(e) {
                   that.openMarkeInfo(e,i)
                 })
              }
              // this.lnglats = [...arr];
              // console.log(this.lnglats);
              // that.carGPSIP();
            }
          });
        // this.lng = lng;
        // this.lat = lat;
        // this.center = [lng, lat];
        // let o = amapManager.getMap();
        // if (!this.marker) {
        //   this.marker = new AMap.Marker({
        //     position: center
        //   });
        //   this.marker.setMap(o);
        // }
        // this.marker.setPosition(center);
        // this.myMap.setCenter(center);
        // // 近来补充  根据经纬度查询地址
        // let geocoder = new AMap.Geocoder({});
        // let that = this;
        // geocoder.getAddress(location, function(status, result) {
        //   console.log(status, result);
        //   if (status === "complete" && result.regeocode) {
        //     that.address = result.regeocode.formattedAddress;
        //   } else {
        //     console.log("根据经纬度查询地址失败");
        //   }
        // });
      }
    },
    // 搜索地址
    searchKeyword() {
      console.log(this.markersArray);
      var keyword = this.fristForm.address;
      // this.clearOverlays(this.markersArray);
      //根据输入的城市设置搜索范围
      // searchService.setLocation("北京");
      //根据输入的关键字在搜索范围内检索
      if (keyword) {
        // searchService.search(keyword);
        geocoder.getLocation(keyword);
      } else {
        alert("请输入地址");
      }
    },

    //基础点
    carGPSIP() {
      var that = this
      const map = new AMap.Map("container", { resizeEnable: true }); //初始化地图
      that.myMap = map;
      if ((that.lng, that.lat)) {
        const center = [that.lng, that.lat];
        that.myMap.setCenter(center);
      }
      //信息窗口实例
      // var infoWindow = new AMap.InfoWindow({ offset: new AMap.Pixel(0, -30) });
      that.getElectronicFenceList()
      map.setFitView();
      //遍历生成多个标记点
      // that.myMap.remove(marker)
      if (that.lnglats.length > 0) {
      // for (var i = 0, marker; i < that.lnglats.length; i++) {
      //   var marker = new AMap.Marker({
      //     position: that.lnglats[i], //不同标记点的经纬度
      //     map: map
      //   });
      //   // marker.content = '我是第' + (i + 1) + '个Marker';
      //   marker.on("click", function markerClick(e) {
      //   // infoWindow.setContent(e.target.content);
      //   // infoWindow.open(this.myMap, e.target.getPosition());
      //   that.openMarkeInfo(e,i)
      // });
      //   // marker.emit("click", { target: marker }); //默认初始化不出现信息窗体,打开初始化就出现信息窗体
      // }

       that.lnglats.forEach((ite,i) => {
           var marker = new AMap.Marker({
          position: that.lnglats[i], //不同标记点的经纬度
          map: map
        });
        marker.on("click", function markerClick(e) {
         that.openMarkeInfo(e,i)
        })
        
      })
      }
    },
     //在指定位置打开信息窗体
     openMarkeInfo(tar,i) {
      let e = this.tableData[i]
        //构建信息窗体中显示的内容
        var info = [];
        // info.push("<div style=\"padding:7px 0px 0px 0px;width:200px;\">");
        info.push("<p class='input-item'>车辆编号 : " + e.deviceNum +"</p>");
        info.push("<p class='input-item'>锁ID : " + e.deviceCode +"</p>");
        info.push("<p class='input-item'>所属站点 :" + e.electronicFenceName + "</p>");

        let infoWindow = new AMap.InfoWindow({
            content: info.join(""),  //使用默认信息窗体框样式，显示信息内容
            // autoMove:true,
            anchor:'bottom-center',
            // position:[e.lnglat.lng,e.lnglat.lat],
        });
        infoWindow.open(this.myMap, tar.lnglat);
     },
    getListData() {
      // 获取列表
      // this.queryInfo.condition.keyWord = this.searchInput;
      var that = this;
      var data = that.queryInfo;
      that.$http
        .post("/device/list", {
          currPage: data.currPage,
          pageSize: data.pageSize,
          condition: that.queryInfo.condition
        })
        .then(function(response) {
          if (response.data.code == 200) {
            that.tableData = response.data.data.data;
            for (let i = 0; i < that.tableData.length; i++) {
              // console.log(that.tableData[i].latitude);
              that.arr.push([
                that.tableData[i].longitude,
                that.tableData[i].latitude
              ]
              );
              console.log(that.arr);
            }
            for (let i = 0; i < that.arr.length; i += 2) {
              var res = [parseFloat(that.arr[i]), parseFloat(that.arr[i + 1])];
              that.arr1.push(res);
            }
            // console.log("-----------");
            console.log('arr',that.arr);
            // that.lnglats = that.arr1;
            that.lnglats = that.arr;
            that.carGPSIP();
            // console.log(that.lnglats);
          }
        });
    }
  }
};
</script>

<style lang="less" scoped>
@import url("https://a.amap.com/jsapi_demos/static/demo-center/css/demo-center.css");


/deep/ .el-select .el-input.is-focus .el-input__inner{
  border: none;
  border-color: #fff;
}
 /deep/ .el-vue-search-box-container .search-box-wrapper .search-btn{
  font-size: 15px;
 }
 /deep/ .el-select .el-input .el-select__caret{
  display: none;
 }
 /deep/ .el-select .el-input .el-select__caret.is-reverse{
  display: none;
 }
#container {
  width: 100%;
  height: 500px;
}

.input-card .btn {
  margin-right: 1.2rem;
  width: 9rem;
}

.input-card .btn:last-child {
  margin-right: 0;
}
.box {
  width: 360.2px;
  background: #a3cdff;
  border-radius: 5px;
  .title {
    height: 44px;
    text-align: center;
    line-height: 44px;
    font-size: 16px;
    color: white;
  }
}
.searchBox {
  display: flex;
  align-items: center;
  height: 45px;
  background: white;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 15%);
  border-radius: 2px 3px 3px 2px;
}
.searchBtn {
  width: 45px;
  text-align: center;
  font-size: 15px;
}
.searchBtn:hover {
  cursor: pointer;
}
/deep/ .el-input--small .el-input__inner {
  border: none;
  height: 20px !important;
}
</style>
